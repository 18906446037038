import * as React from 'react'

import { FormattedMessage } from 'react-intl';

import IndexLayout from '../layouts'
import LilaSection from '../components/LilaSection'
import styled from '@emotion/styled'

import DsBook from '../components/ds/DsBook'
import LocalizedLink from '../components/links/LocalizedLink'
import DsNav from '../components/nav/DsNav'
import { fonts } from '../styles/variables'
import { LilaRef } from '../constants/lilaStyles'
var Scroll = require('react-scroll');
var Element = Scroll.Element;



const Chapter = styled.div`
width:100%;
display:flex;
padding-left: 8rem;
padding-right: 12rem;
padding-top: 4rem;
text-align:left;

 @media screen and (max-width: 1199px) {
padding-left: 10%;
padding-right: 10%;

}
`

const ChapterText = styled.div`
width:70%;
margin-bottom:4rem;

 @media screen and (max-width: 1199px) {

width:100%;

}


`

const SubP = styled.p`
text-align: left;
margin-top:1.2rem;
margin-bottom:1rem;
letter-spacing:1px;

`

const P = styled.p`
text-align: left;
letter-spacing:0px;
font-size:1rem !important;
margin-bottom:0px;
padding-bottom:0px;
display:flex;

`


const LilaLink = styled(LocalizedLink)`
margin-right:5px;
color:var(--hase);

`


const H1 = styled.h1`
font-size:2rem;
`



const LilaList = styled.ul`
list-style: circle;
font-family:${fonts.bcHairLine};

`

const LilaCentered = styled.div`
width:100%;
justify-content:center;
display:flex;
`



const Datenschutz = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <DsBook title={<FormattedMessage id={'ds_0'}/>} subTitle1={''} subTitle2={''}/>


    <Element id="chOne"/>
    <div id={'section-1'}>
      <LilaSection id={'first'} title={''}></LilaSection>
      <Chapter id={'chapter1'}>
        <DsNav />
        <ChapterText>
          <H1> <FormattedMessage id={'ds_header_1'}/> </H1>
          <SubP><FormattedMessage id={'ds_sub_1_1'}/></SubP>

          <P> <FormattedMessage id={'ds_1_1'}/> </P>
          <br/>
          <P> <LilaLink to={'/'}>www.lilahase.com</LilaLink> (im Folgenden „Webseite“ genannt)</P>
          <br/>

          <P> <FormattedMessage id={'ds_1_1_1'}/> </P>


          <SubP><FormattedMessage id={'ds_sub_1_2'}/></SubP>
          <P> <FormattedMessage id={'ds_1_2'}/></P>

          <SubP><FormattedMessage id={'ds_sub_1_3'}/></SubP>
          <P> <FormattedMessage id={'ds_1_3'}/></P>

          <SubP><FormattedMessage id={'ds_sub_1_4'}/></SubP>
          <P> <FormattedMessage id={'ds_1_4'}/></P>



        </ChapterText>
      </Chapter>
    </div>

    <Element id="chTwo"/>
    <div id={'section-2'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter2'}>
        <DsNav />
        <ChapterText>
          <H1><FormattedMessage id={'ds_header_2'}/> </H1>

          <SubP> <FormattedMessage id={'ds_2_1_1'}/></SubP>

          <P> <FormattedMessage id={'ds_2_1_2'}/></P>
          <P> <FormattedMessage id={'ds_2_1_3'}/></P>
          <P> <FormattedMessage id={'ds_2_1_4'}/></P>
          <P> <FormattedMessage id={'ds_2_1_5'}/></P>


        </ChapterText>
      </Chapter>

    </div>


    <Element id="chThree"/>
    <div id={'section-3'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter3'}>
        <DsNav />
        <ChapterText>
          <H1> <FormattedMessage id={'ds_header_3'}/></H1>
          <SubP><FormattedMessage id={'ds_sub_3_1'}/></SubP>
          <P><FormattedMessage id={'ds_3_1_1'}/></P>




        </ChapterText>
      </Chapter>
    </div>

    <Element id="chFour"/>
    <div id={'section-4'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter4'}>
        <DsNav />
        <ChapterText>
          <H1> <FormattedMessage id={'ds_header_4'}/></H1>
          <SubP><FormattedMessage id={'ds_sub_4_1'}/></SubP>
          <P><FormattedMessage id={'ds_4_1_1'}/></P>
          <br/>
          <LilaList>
            <li><FormattedMessage id={'ds_4_1_2'}/></li>
            <li><FormattedMessage id={'ds_4_1_3'}/></li>
            <li><FormattedMessage id={'ds_4_1_4'}/></li>
            <li><FormattedMessage id={'ds_4_1_5'}/></li>
            <li><FormattedMessage id={'ds_4_1_6'}/></li>
            <li><FormattedMessage id={'ds_4_1_7'}/></li>
            <li><FormattedMessage id={'ds_4_1_8'}/></li>
            <li><FormattedMessage id={'ds_4_1_9'}/></li>
            <li><FormattedMessage id={'ds_4_1_10'}/></li>
          </LilaList>
          <br/>

          <P><FormattedMessage id={'ds_4_1_11'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_1_12'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_1_13'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_1_14'}/></P>
          <br/>

          <SubP><FormattedMessage id={'ds_sub_4_2'}/></SubP>
          <P><FormattedMessage id={'ds_4_2_1'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_2_2'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_2_3'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_2_4'}/></P>
          <br/>

          <P><FormattedMessage id={'ds_4_2_5'}/></P>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_4_3'}/></SubP>
          <P><FormattedMessage id={'ds_4_3_1'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_3_2'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_3_3'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_3_4'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_3_5'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_3_6'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_3_7'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_3_8'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_3_9'}/></P>
          <br/>
          <LilaList>
          <li><FormattedMessage id={'ds_4_3_10'}/>
          <LilaRef target="_blank" href={'https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=de_DE.'}>https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=de_DE</LilaRef>.
          </li>
            <br/>
          <li><FormattedMessage id={'ds_4_3_11'}/>
          <LilaRef target="_blank" href={'https://stripe.com/de/privacy'}>https://stripe.com/de/privacy</LilaRef>.
          </li>
            <br/>
          <li><FormattedMessage id={'ds_4_3_12'}/>
            <LilaRef target="_blank" href={'https://pay.amazon.de/help/201212490'}>https://pay.amazon.de/help/201212490</LilaRef>.
          </li>
            <br/>
          <li><FormattedMessage id={'ds_4_3_13'}/>
            <LilaRef target="_blank" href={'https://www.opennode.com/privacy-policy'}>https://www.opennode.com/privacy-policy</LilaRef>.

          </li>

            <br/>
          <li><FormattedMessage id={'ds_4_3_14'}/></li>
          </LilaList>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_4_4'}/></SubP>
          <P><FormattedMessage id={'ds_4_4_1'}/></P>
          <br/>



          <P><FormattedMessage id={'ds_4_4_2'}/></P>

<br/>
          <P><FormattedMessage id={'ds_4_4_3'}/></P>

<br/>
          <P><FormattedMessage id={'ds_4_4_4'}/></P>


<br/>
          <SubP><FormattedMessage id={'ds_sub_4_5'}/></SubP>
          <P><FormattedMessage id={'ds_4_5_1'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_5_2'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_5_3'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_5_4'}/></P>
          <br/>


          <SubP><FormattedMessage id={'ds_sub_4_6'}/></SubP>
          <P><FormattedMessage id={'ds_4_6_1'}/></P>
<br/>
          <P><FormattedMessage id={'ds_4_6_2'}/></P>
<br/>
          <P><FormattedMessage id={'ds_4_6_3'}/></P>
<br/>
          <P><FormattedMessage id={'ds_4_6_4'}/></P>
<br/>
          <P><FormattedMessage id={'ds_4_6_5'}/></P>
<br/>
          <P><FormattedMessage id={'ds_4_6_6'}/></P>
<br/>
          <P><FormattedMessage id={'ds_4_6_7'}/></P>
<br/>
          <P><FormattedMessage id={'ds_4_6_8'}/></P>
          <br/>

          <LilaCentered>    <LilaRef target="_blank" href={'https://de-de.facebook.com/policy.php'}>https://de-de.facebook.com/policy.php</LilaRef></LilaCentered>
<br/>
<br/>
<br/>
          <P><FormattedMessage id={'ds_4_6_9'}/></P>
          <br/>

          <LilaCentered><LilaRef target="_blank" href={'https://de-de.facebook.com/help/instagram/155833707900388'}>https://de-de.facebook.com/help/instagram/155833707900388</LilaRef></LilaCentered>
          <br/>
          <br/>

          <br/>
          <P><FormattedMessage id={'ds_4_6_10'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://twitter.com/de/privacy'}>https://twitter.com/de/privacy</LilaRef></LilaCentered>
          <br/>
          <br/>
          <br/>
          <P><FormattedMessage id={'ds_4_6_11'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://policies.google.com/privacy?hl=de'}>https://policies.google.com/privacy?hl=de</LilaRef></LilaCentered>

          <br/>
          <br/>
          <br/>
          <P><FormattedMessage id={'ds_4_6_12'}/></P>
          <br/>

          <LilaCentered><LilaRef target="_blank" href={'https://www.privacyshield.gov/EU-US-Framework'}>https://www.privacyshield.gov/EU-US-Framework</LilaRef></LilaCentered>

          <br/>
          <br/>
          <br/>

          <SubP><FormattedMessage id={'ds_sub_4_7'}/></SubP>
          <P><FormattedMessage id={'ds_4_7_1'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://marketingplatform.google.com/about/analytics/terms/de'}>https://marketingplatform.google.com/about/analytics/terms/de</LilaRef></LilaCentered>
          <br/>
          <br/>

          <P><FormattedMessage id={'ds_4_7_2'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://support.google.com/analytics/answer/6004245?hl=de&ref_topic=2919631'}>https://support.google.com/analytics/answer/6004245?hl=de&ref_topic=2919631</LilaRef></LilaCentered>

          <br/>
          <br/>
          <br/>
          <P><FormattedMessage id={'ds_4_7_4'}/></P>
          <br/>

          <LilaCentered><LilaRef target="_blank" href={'https://policies.google.com/privacy?hl=de&gl=de'}>https://policies.google.com/privacy?hl=de&gl=de</LilaRef></LilaCentered>

          <br/>
          <br/>
          <P><FormattedMessage id={'ds_4_7_6'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_7_7'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_7_8'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_7_9'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_7_10'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_7_11'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_7_12'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_7_13'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_7_14'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://www.privacyshield.gov/EU-US-Framework'}>https://www.privacyshield.gov/EU-US-Framework</LilaRef></LilaCentered>

          <br/>
          <br/>

          <SubP><FormattedMessage id={'ds_sub_4_8'}/></SubP>

          <P><FormattedMessage id={'ds_4_8_1'}/></P>

          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://www.facebook.com/policy.php '}>https://www.facebook.com/policy.php </LilaRef></LilaCentered>

          <br/>
          <br/>
          <P><FormattedMessage id={'ds_4_8_2'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_8_3'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_8_4'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_8_5'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_8_6'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://www.privacyshield.gov/EU-US-Framework'}>https://www.privacyshield.gov/EU-US-Framework</LilaRef></LilaCentered>


          <br/>
          <br/>

          <SubP><FormattedMessage id={'ds_sub_4_9'}/></SubP>
          <P><FormattedMessage id={'ds_4_9_1'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://www.google.com/intl/de/tagmanager/use-policy.html'}>https://www.google.com/intl/de/tagmanager/use-policy.html</LilaRef></LilaCentered>

          <br/>
          <br/>

          <SubP><FormattedMessage id={'ds_sub_4_10'}/></SubP>
          <P><FormattedMessage id={'ds_4_10_1'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://twitter.com/privacy'}>https://twitter.com/privacy</LilaRef></LilaCentered>

          <br/>
          <br/>
          <P><FormattedMessage id={'ds_4_10_2'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_10_3'}/></P>
          <br/>
          <P><FormattedMessage id={'ds_4_10_4'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://help.twitter.com/de/safety-and-security/privacy-controls-for-tailored-ads'}>https://help.twitter.com/de/safety-and-security/privacy-controls-for-tailored-ads</LilaRef></LilaCentered>

          <br/>
          <br/>
          <P><FormattedMessage id={'ds_4_10_5'}/></P>
          <br/>
          <LilaCentered><LilaRef target="_blank" href={'https://www.privacyshield.gov/EU-US-Framework'}>https://www.privacyshield.gov/EU-US-Framework</LilaRef></LilaCentered>

          <br/>
          <br/>

        </ChapterText>
      </Chapter>
    </div>

    <Element id="chFive"/>
    <div id={'section-5'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter5'}>
        <DsNav />
        <ChapterText>
          <H1> <FormattedMessage id={'ds_header_5'}/></H1>
          <SubP><FormattedMessage id={'ds_sub_5_1'}/></SubP>
          <P><FormattedMessage id={'ds_5_1_1'}/></P>




        </ChapterText>
      </Chapter>
    </div>

    <Element id="chSix"/>
    <div id={'section-6'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter6'}>
        <DsNav />
        <ChapterText>
          <H1> <FormattedMessage id={'ds_header_6'}/></H1>

          <SubP><FormattedMessage id={'ds_sub_6_1'}/></SubP>
          <P><FormattedMessage id={'ds_6_1_1'}/></P>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_6_2'}/></SubP>
          <P><FormattedMessage id={'ds_6_2_1'}/></P>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_6_3'}/></SubP>
          <P><FormattedMessage id={'ds_6_3_1'}/></P>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_6_4'}/></SubP>
          <P><FormattedMessage id={'ds_6_4_1'}/></P>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_6_5'}/></SubP>
          <P><FormattedMessage id={'ds_6_5_1'}/></P>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_6_6'}/></SubP>
          <P><FormattedMessage id={'ds_6_6_1'}/></P>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_6_7'}/></SubP>
          <P><FormattedMessage id={'ds_6_7_1'}/></P>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_6_8'}/></SubP>
          <P><FormattedMessage id={'ds_6_8_1'}/></P>
          <br/>
          <SubP><FormattedMessage id={'ds_sub_6_9'}/></SubP>
          <P><FormattedMessage id={'ds_6_9_1'}/></P>
          <br/>
          <br/>
          <P>Stand: Mai 2020</P>




        </ChapterText>
      </Chapter>
    </div>





  </IndexLayout>
)

export default Datenschutz


/*          <LilaTable>
<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>


<tr>
  <td>1</td>
  <td>2</td>
  <td>3</td>
</tr>

          </LilaTable>

 */
